.button-drop-down {
    position: relative;

}

.button-drop-down > button{
    height: 48px;
}

.button-drop-down .items {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--surface-a);
    min-width: 100px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 2;
    border-radius: 4px;
    display: none;
}

.button-drop-down .items.opened {
    display: block;
}

.button-drop-down .items li button {
    display: block;
    width: 100%;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.button-drop-down .items li button:hover{
    background-color: var(--surface-300);
}