.form-field-wrapper{
    margin-bottom:24px;
    display: flex;
    flex-direction: column;
}

.form-field-wrapper input,
.form-field-wrapper textarea{
    height: 48px;
    border-radius: 12px;
    font-size: 0.875rem;
}

.form-field-wrapper textarea{
   min-height: 120px; 
   line-height: 1.6;
}

.form-field-wrapper label{
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 0.875rem;
}