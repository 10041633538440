.container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    align-items: center;
}
.section-wrapper{
    display: flex;
    flex-direction: column;
    flex: 1
}
.section-wrapper > h4 {
    margin: 0px;
    font-weight: 600;
}
.action-wrapper{
    display: flex;
    flex-direction: row;
    flex: 1
}