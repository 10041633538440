.job {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.title {
  font-size: 15px;
  font-weight: 500;
  margin-top: 7px;
  color: var(--primary-blue-700);
}
.description {
  margin-top: 7px;
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  height: max-content;
  cursor: pointer;
  word-break: break-all;
}
