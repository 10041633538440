.education-card-wrapper{

}
.education-card-title{
    font-size: 15px;
    font-weight: 600;
}
.education-card-degree-title{
    font-size: 13px;
    font-weight: 500;
}
.education-card-date-title{
    font-size: 12px;
    font-weight: 500;
}