.page-header-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 30px 0px;
}
.page-header-title{
    font-size: 20px;
    font-weight: 500
}