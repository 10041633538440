.change-password{
    margin-top:var(--spacing-lg)
}

.change-password h4{
    font-size:1.1rem;
    font-weight: normal;
    margin:0;
    border-bottom:1px solid var(--surface-300);
    padding-bottom: var(--spacing-sm);
    margin-bottom: var(--spacing-md);
}

.change-password .content{
    margin-top:var(--spacing-sm);
}