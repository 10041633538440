.language-card-wrapper{
    min-width: 120px;
    height: 40px;
    margin: 3px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px #7b7b7b;
    padding: 8px;
}
.language-card-wrapper:hover{
    background-color: var(--primary-blue-100)
}
.language-card-title{
    font-size: 13px;
    font-weight: 600
}
.language-card-level-title{
    font-size: 12px;
    font-weight: 400;
    margin-left: 3px
}