.listing-page .q-data-table .p-card{
    box-shadow: none;
    border:1px solid var(--gray-400);
    border-top:none;
    margin-top:0;
    border-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.listing-page .p-datatable .p-datatable-thead > tr > th{
    border:none;
}
