.general-error-message {
    color: red;
    display: flex;
    align-items: center;
    margin-bottom:24px;
}

.general-error-message>span:first-child {
    padding-right: 4px;
    display: flex;
    align-items: center;
}