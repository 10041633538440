.filter-box-item{
    margin-top:-(var(--spacing-dm));
    max-height: 200px;
    overflow-y: auto;
    margin:0 -16px;
}

.filter-box-item > div{
    padding:var(--spacing-sm) var(--spacing-md);
    user-select: none;
}

.filter-box-item > div label{
    margin-left:var(--spacing-sm);
}