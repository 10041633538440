.time-line-item > pre{
    max-height: 200px;
    overflow: auto;
    background-color: var(--surface-300);
    padding:8px;
    border-radius: 8px;
    text-align: left;
}
.event-name {
    margin: 0px;
}
.item-date{
    color: rgba(0,0,0, 0.7)
}