.content{
    display: flex;
    margin-left: -24px;
    margin-right: -24px;

}

 .content > div{
    width: 33.33%;
    padding:0 24px;
 }