.dashboard-sidebar{
    width:300px;
    height: 100%;
    box-shadow: -1px 4px 3px 1px var(--layout-border);
    -webkit-box-shadow: -1px 4px 3px 1px var(--layout-border);
    -moz-box-shadow: -1px 4px 3px 1px var(--layout-border);
    background: var(--white);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dashboard-sidebar .brand{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
}

.dashboard-sidebar .menu{
    padding: 8px;
}

.dashboard-sidebar .menu .p-panelmenu-panel{
    margin-bottom: 5px;
}
/* 
.dashboard-sidebar .s-menu-items{
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashboard-sidebar .s-menu-items li{
    margin-bottom: 8px;
}

.dashboard-sidebar .s-menu-items li a{
    color:var(--gray-800);
    text-decoration: none;
}

.dashboard-sidebar .s-menu-items li a:hover{
    color:var(--text-color);
} */

.dashboard-sidebar .user-box{
    display: flex;
    flex-direction: column;
    padding:0 var(--spacing-md);
    /* position: absolute; */
    padding-bottom: var(--spacing-md);
    width: 100%;
    /* bottom: 0;
    left: 0; */
}

.dashboard-sidebar .user-box a{
    padding:var(--spacing-xs);
    margin-bottom: var(--spacing-sm);
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-bottom: var(--spacing-md);
    text-decoration: none;
    color:var(--text-color);
    border-bottom: 1px solid var(--surface-d);
}

.dashboard-sidebar .user-box a span > div{
    background-color: transparent;
    color:var(--primary-color);
    border:1px solid var(--primary-color);
}

.dashboard-sidebar .user-box a span:nth-child(2){
    font-size: 0.875rem;
    display: block;
    max-width: 200px;
    overflow: hidden;
}

.dashboard-sidebar .user-box button{
    border:none;
    color:var(--red);
    font-size: 1rem;
    padding:var(--spacing-md);
    background-color: transparent;
    text-align: left;
    cursor: pointer;
}

.dashboard-sidebar .user-box button i{
    margin-right: var(--spacing-md);
}