.contract-status-history{
    margin-bottom: 16px;
    padding-left:16px;
    position: relative;
}

.contract-status-history:after{
    content:'';
    position: absolute;
    z-index: 2;
    left:-16px;
    top:50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-color: #555;
    border-radius: 50%;
}

.contract-status-history > div:not(:last-child){
    margin-bottom: 8px;
}