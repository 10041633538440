.sort-and-filters {
    border: 1px solid var(--gray-400);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    
}

.sort-and-filters .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sort-and-filters .controls .buttons{
    display: flex;
    flex-direction: row-reverse;
}

.sort-and-filters .controls .buttons > button{
    height: 48px;
}

.search-box{
    display: flex;
    align-items: center;
}

.search-box input{
    height: 48px;
    border-radius: 8px;
}
