.general-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
}
.general-card-action-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--primary-blue-600);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 40px;
  cursor: pointer;
}

.remove-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 0px;
  cursor: pointer;
}

.switch-button {
  position: absolute;
  top: 0px;
  right: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
