.general-avatar-picker{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}
.general-avatar-picker-edit-icon-wrapper{
    position: absolute;
    bottom: 0px;
    left: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content:center;
    align-items:center;
}