.card-title{
    font-size: 15px;
    font-weight: 600
}
.title{
    font-size: 16px;
    font-weight: 600;
}

.category-title{
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
    color: var(--primary-blue-600)
}
.description{
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
}
.package-title{
    font-size: 14px;
    font-weight: 600;
}
.package-price{
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-blue-600)
}
.package-description{
    font-size: 12px;
    font-weight: 400;
}