.new-category-box legend.p-fieldset-legend{
    border:1px solid var(--surface-300);
}

.new-category-box .new-category-content{
    display: flex;
    margin-left: -24px;
    margin-right: -24px;
}

.new-category-box .new-category-content > div{
    width: 50%;
    padding:0 24px;
}

.image-and-desc {
    color:var(--surface-800);
    font-size:0.875rem;
}