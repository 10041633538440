.dashboard-content{
    display: flex;
    width:100%;
    height:100vh;
}

.dashboard-content .d-content{
    padding: 24px;
    width:calc(100% - 300px);
    max-height: 100vh;
    overflow-y: auto;
    background: var(--white)
}