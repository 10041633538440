.layout-back-button{
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items:center;
    cursor: pointer;
}
.layout-back-button:hover{
    opacity: 0.7
}