.general-details-wrapper-card{
    min-height: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.general-details-title{
    color: var(--text-mute)
}
.active-color{
    baclground-color: var(--light-green)
}
.inactive-color{
    baclground-color: var(--light-red)
}
