.menu-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    color: var(--dark-gray-text);
}

.menu-item i{
    font-size: 0.875rem; 
    color: var(--black);
}


.menu-item:hover{
    cursor: pointer;
    background: var(--primary-blue-50);
    color: var(--primary-blue-900);
}
.menu-item.active{
    color: var(--primary-blue-900);
}
.menu-item .label{
    font-size: 14px;
    margin-left: 10px;
}