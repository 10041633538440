html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size:16px;
}

.element-full-w{
  width: 100%;
}


.qud-table .p-paginator{
  margin-top:24px;
  border:none;
}

.p-panelmenu .p-panelmenu-panel:not(:last-child){
  margin-bottom: 12px;

}


.p-panelmenu .p-panelmenu-panel .p-panelmenu-content{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* 
.p-panelmenu .p-panelmenu-header .p-panelmenu-header-link{
  background-color: #e7e7e7;
} */

.p-button{
  height: 40px;
  border-radius: 8px;
  padding:8px 18px;
}

.p-dropdown ,
.p-multiselect{
  border-radius: 12px;
}

.p-dropdown .p-inputtext{
  padding:15px 12px;
}

.p-multiselect .p-multiselect-label-container{
  padding:7px 12px;
}

.item-card-trasparent{
  background-color: transparent;
  box-shadow: none;
}

.item-card-trasparent .p-card-title,
.item-card-trasparent .p-card-subtitle{
  text-align: center;
}

.item-card-trasparent .p-card-title{
  font-size: 2rem !important;
  font-weight: 700 !important;
}

.item-card-trasparent .p-card-subtitle{
  font-size: 0.875rem;
}

.p-fieldset{
  border-radius: 12px;
}

.p-fieldset h3{
  margin-top: 0;
}


.p-tabview-panels{
  padding:0 !important;
}


:root {
  --primary-blue-50: #f2f5f9;
  --primary-blue-100: #e7f0f0;
  --primary-blue-200: #DCF0F3;
  --primary-blue-300: #B8E2E7;
  --primary-blue-400: #71C5CE;
  --primary-blue-500: #4EB6C2;
  --primary-blue-600: #46A4AF;
  --primary-blue-700: #3E929B;
  --primary-blue-800: #377F88;
  --primary-blue-900: #2F6D74;
  --white: #ffffff; 
  --layout-border: #e5e5e5;
  --text-mute: #858585;
  --light-red: #ffb9b9;
  --red: #ff5858;
  --light-green: #b9ffca;
  --green: #2ab24b;
  --dark-gray-text: #545454;
  --spacing-xs:4px;
  --spacing-sm:8px;
  --spacing-md:16px;
  --spacing-lg:24px;
  --spacing-xl:48px;
  --border-radius:8px;
}