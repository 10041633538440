.general-layout-wrapper .empty-box{
    width: 100vw;
    height: 100vh;
    position: relative;
}

@keyframes rotateItem {
    0%{
        transform: rotate(0);
        border-radius: 0;
    }
    50%{
        transform: rotate(180deg);
        border-radius: 50%;
    }
    100%{
        transform: rotate(0);
        border-radius: 0;
    }
}

.general-layout-wrapper .empty-box:after,
.general-layout-wrapper .empty-box:before{
    content:"";
    position: absolute;
    width: 40px;
    height: 40px;
    border:3px solid var(--primary-color);
    top:50%;
    left:50%;
    margin-top:-20px;
    margin-left: -20px;
    animation: rotateItem infinite ease;
    animation-duration: 3s;
}

.general-layout-wrapper .empty-box:before{
    animation-duration: 2s;
}