@keyframes l_spinner {
    from{
        left:0;
    }
    to{
        left:100%;
    }
}

.spinner{
    width: 100%;
    height: 5px;
    position: relative;
    overflow: hidden;
    background-color: #f6f6f6;
    margin-top:16px;
}

.spinner:after{
    content:"";
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    animation: l_spinner;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    background-color: var(--primary-color);
    width: 20%;
    border-radius: 4px;
}