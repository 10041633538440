.highlight-text-wrapper{
    border-radius: 8px;
    display: flex;
    justify-content:center;
    align-items: center;
}
.highlight-text-verified-color{
    color: var(--green);
}
.highlight-text-not-verified-color{
    color: var(--red);
}
.highlight-text{
    font-size: 13px;
    font-weight: 600;
}

