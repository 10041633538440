.general-navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(255,255,255);
    height: 100px;
    background: linear-gradient(180deg, #C5F0F7 0%, rgba(237, 248, 249, 0) 100%);
}

.general-navbar a{
    text-decoration: none;
    color: var(--color-text);
}