.qud-time-line{
    background-color: #fff;
    padding:16px;
    margin-top:24px;
    border-radius: 8px;
}

.qud-time-line.is-loading{
    position: relative;
}

.qud-time-line.is-loading:after{
    content:"";
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0,0.4);
    border-radius: 8px;
}