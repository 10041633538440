.freelancer-service-card{
    min-height: 120px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.freelancer-service-card-title{
    font-size: 15px;
    font-weight: 600;
}
.freelancer-service-card-category-title{
    font-size: 15px;
    font-weight: 500;
    margin-top: 7px;
    color: var(--primary-blue-700);
}
.freelancer-service-card-description{
    margin-top: 7px;
    font-size: 13px;
    font-weight: 500;
}
.freelancer-service-card-footer{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
    color: var(--primary-blue-800) ;
}