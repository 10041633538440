.new-administrator-box legend.p-fieldset-legend{
    border:1px solid var(--surface-300);
}

.new-administrator-box .new-admin-content{
    display: flex;
    margin-left: -24px;
    margin-right: -24px;
}

.new-administrator-box .new-admin-content > div{
    width: 50%;
    padding:0 24px;
}