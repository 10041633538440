.filters{
    width: 400px;
    height: 100%;
    position: fixed;
    z-index: 10;
    right: 0;
    top:0;
    border-left: 1px solid var(--surface-300);
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);
    background-color: var(--surface-a);
    display: none;
}

.filters.opened{
    display: block;
}

.filters > h2{
    padding:var(--spacing-md);
    text-align: center;
    font-weight: 600;
    margin:0;
    border-bottom: 1px solid var(--surface-300);
}

.close-btn{
    position: absolute;
    left:-20px;
    top:10px;
}

.accordion > div{
    box-shadow: none !important;
    border-bottom: 1px solid var(--surface-300);
}

.filter-items{
    max-height: calc(100% - 136px);
    overflow-y: auto;
}

.inner-filter-items{
    max-height: 200px;
    overflow-y: auto;
}

.checkbox-item{
    padding:var(--spacing-sm) 0;
}

.checkbox-item label{
    margin-left: var(--spacing-sm);
}

.actions{
    display: flex;
    align-items: center;
    padding:-16px;
    margin-top:8px;
}

.actions > div{
    width: 50%;
    padding:0 16px;
}
.actions > div button{
    display: block;
    width: 100%;
}